.options{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
}

.container-fluid{
    height: 85vh;
    overflow-y: auto;
}

.form-check-input{
    margin-right: 5px;
}

.dropdown-menu{
    max-height: 80vh;
    overflow-y: scroll;
}

.form-select-sm{
    width:100px;
    height: 38px;
}

.event-name-title{
    margin-left: 5px;
}

.dropdown-item:hover{
    background-color: #c2c2c2;
}

.input-group{
    width: 200px;
}

.form-control.bg-dark::placeholder { 
    color: grey;
    opacity: 1; 
}

.btn-toolbar{
    margin-right: 5px;
}

/* Custom breakpoints */
@media (min-width: 1800px) {
    .col-xxxl-2 {
        flex: 0 0 16.666667%; /* 6 columns per row */
        max-width: 16.666667%;
    }
}
