.stream-container {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
}

.stream-table {
    margin-right: 20px;
}

.scrollable-table-container {
    max-height: 80vh;
    overflow-y: auto; 
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.word-list {
    flex: 1;
}

.word-list input {
    padding: 8px;
    margin-right: 10px;
}

.word-list button {
    padding: 8px 10px;
    cursor: pointer;
}

.word-list tbody tr {
    list-style-type: none;
    padding: 0;
    max-height: 70vh;
    overflow-y: auto;
}

.delete-button {
    padding: 5px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #c82333;
}

.selectors{
    display: block;
    margin-right: 10px;
}

.button-row{
    min-width: 142px; /*intentionally set to not make the buttons glitch into two lines*/
}